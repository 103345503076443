<template>
  <div class="container">
    <v-sheet min-height="35" />
    <v-row>
      <v-col cols="3" />
      <v-col cols="6">
        <v-card class="custom-form">
          <v-sheet
            min-height="50px"
            color="orange darken-3"
            style="padding-top: 10px"
            dark
          >
            <h2 style="font-weight: normal">Please Register Now</h2>
          </v-sheet>
          <v-sheet min-height="20" />
          <v-row>
            <v-col cols="2" />
            <v-col cols="8">
              <v-text-field
                v-model="email"
                :value="email"
                label="Email Address"
                :rules="[rules.required, rules.email]"
                outlined
                rounded
              />
              <v-text-field
                v-model="password"
                :value="password"
                label="Password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                :rules="[rules.required, rules.min]"
                outlined
                rounded
              />
              <v-text-field
                v-model="passwordVerify"
                :value="passwordVerify"
                label="Password Verify"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                @click:append="show2 = !show2"
                :rules="[rules.required, rules.min, rules.passwordMatch]"
                outlined
                rounded
              />
              <v-text-field
                v-model="firstName"
                :value="firstName"
                label="First Name"
                :rules="[rules.required]"
                outlined
                rounded
              />
              <v-text-field
                v-model="lastName"
                :value="lastName"
                label="Last Name"
                :rules="[rules.required]"
                outlined
                rounded
              />
              <v-text-field
                v-model="company"
                :value="company"
                label="Company"
                :rules="[rules.required]"
                outlined
                rounded
              />
              <v-card>
                <v-card-text>
                  <b>You are registering to access iCatalysts' Network Analyzer Demo.
                  This demo is based on actual data, but identities have been anonymized.
                  This demo illustrates how to use the analyzer to understand overall network structure and health (e.g. density, interconnectivity, etc.), company relationships, and importance.
                  Users can utilize the network or map view to analyze this network.</b>
                </v-card-text>
              </v-card>
              <br />
            </v-col>
          </v-row>
          <v-btn @click="register" color="blue" dark class="mt-4"> Register </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
export default {
  data() {
    return {
      email: "",
      password: "",
      passwordVerify: "",
      firstName: "",
      lastName: "",
      company: "",
      show1: false,
      show2: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (value) => value.length >= 8 || "Min 8 characters.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        passwordMatch: () =>
          this.password === this.passwordVerify || "Password must match.",
      },
    };
  },
  methods: {
    async register() {
      try {
        await Auth.signUp({
            username: this.email.trim(),
            password: this.password,
            attributes: {
              family_name: this.lastName.trim(),
              given_name: this.firstName.trim(),
              "custom:company": this.company.trim()
            }
        });
        this.$router.push("verify");
      } catch (error) {
        alert(error.message);
      }
    },
  },
};
</script>
